<template>
	<div class="payment-method__wrap">
		<div class="payment-method__header">
			<div
				class="payment-method-second-step__next-step"
				@click="$emit('handleChangeStep', 1)"
			>
				<ArrowBackIcon/>
				<h2 class="payment-method__title">Способ оплаты</h2>
			</div>
			<p class="payment-method__subtitle">Выберите карту по-умолчанию из списка:</p>
		</div>
		<CardList
			@handleChooseCard="handleChooseCard"
			:currentCardId="currentCard.ID"
		/>
	</div>
</template>
<script>
import {CardList} from "ui";
import {mapGetters, mapActions} from "vuex";
import ArrowBackIcon from "icons/24/arrow-back.svg?inline";

export default {
	name: "SecondStep",
	components: {CardList, ArrowBackIcon},
	computed:
		{
			...mapGetters({currentCard: "personal/getDefaultCard",})
		},
	methods:
		{
			...mapActions({
				fetchSaveCard: 'personal/fetchSaveCard'
			}),
			async handleChooseCard(card)
			{
				await this.fetchSaveCard(card.ID);
				this.$emit('closePopup')
			}
		}
}
</script>
<style
	lang="scss"
>
.payment-method-second-step__next-step
{
	display: flex;
	align-items: center;
	gap: 12px;

	cursor: pointer;
}
</style>